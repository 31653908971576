<div [@fadeTransition] class="flex flex-col items-center h-full">
  <div [@fadeTransition] class="flex flex-col items-center">
    <div class="text-center xl:mt-[53px] lg:mt-[66px] mt-[20px]">
      <p class="font-light xl:text-[49.5px] lg:text-[39.5px] sm:text-[72px] text-[26px]"><strong class="font-bold">Welcome!</strong>
      </p>
      <p class="font-medium xl:text-[22.5px] sm:text-[28px] lg:text-[18px] sm:px-8 lg:px-0 lg:mt-0 text-[16px] px-4 mt-4">
        We're here to put the
        power back in the hands of artists,
        with powerful
        tools<br> that can change the
        trajectory of your music career. Control your music, your way!</p>
      <button (click)="checkLogin()" class="main-button-design gradient-button xl:mt-[37px] lg:mt-[30px] mt-[23px]">
        Start Using Dstro
      </button>
    </div>
  </div>
</div>
