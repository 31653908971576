import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {UntilDestroy} from '@ngneat/until-destroy';
import {MenuComponent} from '../menu/menu.component';
import {UserStateService} from '../../services/user-state.service';
import {slideMenu} from '../../animations/slide-menu-animation';
import {LoadingService} from '../../services/loading.service';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [slideMenu],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavbarComponent implements OnInit {
  user$ = this.userStateService.user$;
  isLoading$ = this.loadingService.isLoading$;
  isRobotShown = true;

  isMenuOpen: boolean = false;
  @ViewChild(MenuComponent) menuComponent!: MenuComponent;

  constructor(private userStateService: UserStateService,
              private loadingService: LoadingService,
              private router: Router,
              private cdr: ChangeDetectorRef) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      window.scrollTo(0, 0);

      this.isRobotShown = event.urlAfterRedirects !== '/profile';

      this.cdr.markForCheck();
    });
  }

  ngOnInit() {
    this.isRobotShown = this.router.url !== '/profile';
  }

  onMenuToggle(isMenuOpen: boolean) {
    this.isMenuOpen = isMenuOpen;
  }

  toggleMenu() {
    this.menuComponent.toggleMenu();
  }

  logout() {
    this.userStateService.logoUt();
  }
}
