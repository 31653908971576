<div [@fadeTransition] class="article-container">
  <div class="flex flex-col lg:flex-row lg:pt-[51px] pt-[80px]">
    <div class="article-content">
      <p class="hidden md:block article-content__title xl:-mt-[11px] -mt-[50px]">
        About Us - Pioneering the Future of Music
      </p>

      <p class="block md:hidden article-content__title xl:-mt-[11px] -mt-[50px]">
        About Us:<br>Pioneering the Future of Music
      </p>

      <p class="article-content__text">Welcome to our world, where music and technology come together to create
        something extraordinary.
        We're a team of passionate musicians, experienced technologists, and visionary entrepreneurs with a
        shared belief in the power of innovation to transform the music industry. Our journey started with a
        simple question: how can we use technology to empower artists and reshape the music landscape?<br><br>
        We didn't just create a product; we envisioned a suite of tools to support artists at every stage of their
        journey. Our mission is to liberate artists from the traditional constraints of music production and
        distribution, allowing them to focus on what they do best: making music.</p>

      <p class="article-content__subtitle">
        Our Vision
      </p>
      <p class="article-content__text">We see a future where technology empowers artists, giving them the freedom to
        create, share, and profit from their music on their terms. Our vision encompasses more than just one product;
        it's about redefining the music industry to be more accessible, inclusive, and innovative.</p>

      <p class="article-content__subtitle">
        Our Suite of Products
      </p>
      <p class="article-content__text">Our first product, Dstro, is a comprehensive music distribution platform that
        helps artists share their music with the world while maintaining independence. But this is just the beginning.
        We're developing a range of AI-driven tools designed to revolutionize every aspect of the music industry—from
        song creation and production to promotion and distribution. Each product in our suite aims to simplify
        processes, giving artists more time to explore their creativity.</p>

      <p class="article-content__subtitle">
        Our Mission
      </p>
      <p class="article-content__text">We believe that artists should have control over their music and careers.
        Our mission is to provide the tools and resources they need to succeed, enabling them to build sustainable
        and rewarding paths in the music industry. By democratizing music production and distribution, we're creating
        opportunities for artists everywhere.</p>

      <p class="article-content__subtitle">
        Our Roots
      </p>
      <p class="article-content__text">We're a team of musicians and technologists who understand the challenges
        artists face because we've experienced them ourselves. This shared experience drives our commitment to
        creating practical, effective solutions that address the real needs of artists. We aim to build a community
        where everyone feels valued, and creativity knows no bounds.</p>

      <p class="article-content__subtitle">
        Join the Movement
      </p>
      <p class="article-content__text">We're not just a company; we're a movement to transform the music industry.
        Our goal is to empower artists and reshape the music landscape, creating a more inclusive and innovative
        future. We invite you to join us on this journey as we continue to push the boundaries of
        what's possible in music.</p>
    </div>
    <div class="articles">
      <div [routerLink]="'/contact'" class="action cursor-pointer">
        <div class="action--img">
          <img alt="subscription" fill ngSrc="assets/images/help/contact.webp">
        </div>
        <p class="action--title">Contact Us:</p>
        <p class="hidden lg:block action--subtitle">Need help to get started? reach out to us and we'll be happy to
          assist!</p>
        <p class="block lg:hidden action--subtitle">Need help to get started?<br> reach out to us and we'll be happy to
          assist!</p>
      </div>
    </div>
  </div>
  <sbz-footer></sbz-footer>
</div>
