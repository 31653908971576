<button [attr.aria-expanded]="isOpen" [class.opened]="isOpen"
        aria-label="Main Menu"
        class="menu-button bg-transparent focus:outline-none border-transparent text-center inline-flex items-center !shadow-none"
        type="button">
  @if (user$ | async) {
    <div *ngIf="(user$ | async) as user" (click)="toggleMenu()" class="user-image">
      <ng-container *ngIf="user?.pictureUrl; else defaultAvatar">
        <img class="rounded-full" fill [ngSrc]="user.pictureUrl"
              alt="Rounded avatar" priority
              referrerpolicy="no-referrer">
      </ng-container>
    
      <ng-template #defaultAvatar>
        <div class="rounded-full bg-gray-300 flex items-center justify-center text-white user-image-avatar">
          {{ user.email.charAt(0).toUpperCase() }}
        </div>
      </ng-template>
    </div>
    <!-- <div class="user-image" (click)="toggleMenu()">
      <img class="rounded-full" fill [ngSrc]="(user$ | async)!.pictureUrl" alt="Rounded avatar"
           referrerpolicy="no-referrer">
    </div> -->
  } @else {
    <span class="menu-button-text" routerLink="/login">LOGIN</span>
  }
  <svg (click)="toggleMenu()" height="40" viewBox="0 0 100 100" width="40">
    <path class="line line1"
          d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331
                94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942
                75.000211,74.999942 L 25.000021,25.000058"/>
    <path class="line line2" d="M 20,50 H 80"/>
    <path class="line line3"
          d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669
                94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058
                75.000211,25.000058 L 25.000021,74.999942"/>
  </svg>
</button>
