<nav class="bg-[#f1f1f3] fixed w-[100vw] z-20 top-0 start-0" id="navbar">
  <div class="nav-bar-container">
    <a [routerLink]="'/'">
      <img alt="logo" fill ngSrc="assets/images/logo.webp" priority>
      <!--      <img alt="logo" fill ngSrc="assets/images/destro-beta.png" priority>-->
    </a>
    <div class="flex order-2 justify-end w-auto bg-transparent border-transparent">
      <sbz-menu (onMenuToggle)="onMenuToggle($event)"></sbz-menu>
    </div>
  </div>
</nav>

<div [ngClass]="{'blur-md opacity-[.4] pointer-events-none': isMenuOpen}"
     class="relative transition-all main-content">
  <ng-container *ngIf="isRobotShown">
    <sbz-robot></sbz-robot>
  </ng-container>
  <div [ngClass]="{'blur-md opacity-[.8] pointer-events-none': isLoading$ | async}" class="router-container">
    <router-outlet></router-outlet>
  </div>
</div>

@if (isMenuOpen) {
  <div @slideMenu class="menu-container flex flex-col" (outsideClick)="toggleMenu()" (click)="toggleMenu()">
    @if (user$ | async) {
      <a [routerLink]="'/profile'">User Profile</a>
    }
    <a [routerLink]="'/subscribe'">Pricing</a>
    <a [routerLink]="'/about'">About Us</a>
    <a [routerLink]="'/contact'">Contact Us</a>
    <a [routerLink]="'/help'">Help and Support</a>
    <!-- <a href="https://discord.com/channels/1239253617501012178/1251928799105388544" target="_blank">User community</a> -->
    @if (user$ | async) {
      <a [routerLink]="'/'" (click)="logout()">Logout</a>
    }
  </div>
}
