import {ChangeDetectionStrategy, Component} from '@angular/core';
import {fadeTransition} from '../../../animations/fade-in-animation';
import {articles} from '../../../interfaces/article';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'sbz-help-and-support',
  templateUrl: './help-and-support.component.html',
  styleUrl: './help-and-support.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HelpAndSupportComponent {
  articles = articles;
  iframeLink: string | null = null;

  constructor(private sanitizer: DomSanitizer) {
  }

  sanitizedUrl(url: string): SafeResourceUrl {
    const videoId = new URL(url).searchParams.get('v');
    const safeUrl = `https://www.youtube.com/embed/${videoId}`;
    return this.sanitizer.bypassSecurityTrustResourceUrl(safeUrl);
  }

  downloadPdf(): void {
    const link = document.createElement('a');
    link.href = 'assets/files/terms.pdf';
    link.download = 'Terms of Service - Dstro.pdf';
    link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
    link.remove(); // Clean up
  }
}
