<div [@fadeTransition] class="w-full flex justify-center">
  <div [ngClass]="{'lg:!w-[845px] sm:!w-[677px]': (user$ | async) && ((userNeedToRegister$ | async))}"
       class="white-container sm:w-[896px] sm:max-w-none w-[90%] max-w-[896px] !rounded-[30.3px] md:!rounded-[42px]">
    @if ((user$ | async) && (userNeedToVerifyEmail$ | async)) {
      @if ((user$ | async)?.email) {
        <div class="text-center relative">
          <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light"><strong
            class="font-bold">Thank you!</strong></p>
            <br />
          <p class="font-prop-normal text-center text-[14px] leading-[25.5px] font-poppins">
            Please check your email inbox.
            {{ (user$ | async)?.email }}
          </p>
          <br />
        </div>
      } @else {
        <div class="white-container-wrapper">
          <div class="text-center relative">
            <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light"><strong
              class="font-bold">Please Enter your email adress</strong></p>
              <br />
            
            <section class="flex-grow w-full ps-[18px] m-[10px] mt-0 sm:mt-0 custom-login-container">
              <form [formGroup]="emailForm" (ngSubmit)="submitEmail()">
                <div class="input-container">
                  <input type="email" placeholder="Enter your email" formControlName="email" class="input-style" />
                </div>

                <div class="flex justify-center space-x-2">
                  <div class="parent-container">
                    <button type="submit"
                            class="mt-6 black-button text-white px-4 py-2 rounded-lg w-full xl:!w-[301px] lg:!w-[301px] sm:!w-[301px] !w-[301px] main-button-design mx-auto">
                      Verify
                    </button>
                  </div>
                </div>
              </form>
            </section>
          </div>
        </div>
      }
    } @else if (activeForm === 'forgotPassword' && activeSubForm === 'verifyCode') {
      <div class="text-center relative">
        <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light"><strong
          class="font-bold">Thank you!</strong></p>
          <br />
        <p class="font-prop-normal text-center text-[14px] leading-[25.5px] font-poppins">
          Please check your email inbox.
          {{ (user$ | async)?.email }}
        </p>
        <br />
      </div>
    } @else if ((user$ | async) && (userNeedToRegister$ | async) && !(userNeedToEnterNewPassword$ | async)) {
      <sbz-register [activeStep]="activeStep" [submitForm]="submitForm"
                    (forceStepChange)="changeActiveStep($event)"></sbz-register>
    } @else {
      <div class="text-center relative">
        <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light">Let's <strong
            class="font-bold">Login</strong></p>
        <p class="font-prop-normal text-center text-[14px] leading-[25.5px] font-poppins">
          Not a user?
          <span
              class="text-[#d13e69] font-poppins cursor-pointer"
              (click)="showRegisterForm()">Register now</span>.
        </p>
      </div>

      <div class="white-container-wrapper">
        <div class="content-container grid grid-cols-1 lg:grid-cols-[1fr_auto_1fr] gap-6">
          <!-- Descope Social Login Section -->
          <section class="flex-grow w-full ps-[18px] m-[10px] custom-social-login-container">
            <button
                class="social-button google-button mt-1 block w-full border border-gray-300 rounded-md shadow-sm input-style placeholder-[#25293166]"
                (click)="loginWithGoogle()">
              <svg width="1.5em" height="1.5em" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="" data-icon="google"><path d="m19.6 10.2274c0-.70912-.0636-1.39092-.1818-2.04552h-9.4182v3.86822h5.3818c-.2318 1.25-.9363 2.3091-1.9954 3.0182v2.5091h3.2318c1.8909-1.7409 2.9818-4.3046 2.9818-7.35z" fill="#4285f4"></path><path d="m10 19.9999c2.7 0 4.9636-.8955 6.6181-2.4227l-3.2318-2.5091c-.8954.6-2.0409.9545-3.3863.9545-2.6046 0-4.8091-1.7591-5.5955-4.1227h-3.3409v2.5909c1.6455 3.2682 5.0273 5.5091 8.9364 5.5091z" fill="#34a853"></path><path d="m4.4045 11.8999c-.2-.6-.3136-1.2409-.3136-1.89997 0-.6591.1136-1.3.3136-1.9v-2.5909h-3.3409c-.6772 1.35-1.0636 2.8773-1.0636 4.4909 0 1.61357.3864 3.14087 1.0636 4.49087z" fill="#fbbc04"></path><path d="m10 3.9773c1.4681 0 2.7863.5045 3.8227 1.4954l2.8682-2.8682c-1.7318-1.6136-3.9955-2.6045-6.6909-2.6045-3.9091 0-7.2909 2.2409-8.9364 5.5091l3.3409 2.5909c.7864-2.3636 2.9909-4.1227 5.5955-4.1227z" fill="#e94235"></path></svg>
              Continue with Google
            </button>
            <button
                class="social-button facebook-button mt-1 block w-full border border-gray-300 rounded-md shadow-sm input-style placeholder-[#25293166]"
                (click)="loginWithFacebook()">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="5 5 20 20" fill="currentColor" data-icon="facebook">
                <path d="M25 15.0609C25 9.50385 20.5234 5 15 5C9.47658 5 5 9.50385 5 15.0609C5 20.0835 8.65625 24.2454 13.4375 25V17.9691H10.8984V15.0609H13.4375V12.8444C13.4375 10.3232 14.9297 8.93004 17.2148 8.93004C18.3086 8.93004 19.4531 9.12655 19.4531 9.12655V11.6025H18.1914C16.9492 11.6025 16.5625 12.3787 16.5625 13.1745V15.0609H19.3359L18.8926 17.9691H16.5625V25C21.3437 24.2454 25 20.0835 25 15.0609Z"></path>
              </svg>
              Continue with Facebook
            </button>
          </section>

          <!-- Divider -->
          <div class="divider flex items-center lg:ps-[68px] lg:pe-[49px] ps-[18px] m-[0px] sm:flex md:hidden">
            <hr class="flex-grow border-gray-300"/>
            <span class="mx-2 text-sm text-gray-500">OR</span>
            <hr class="flex-grow border-gray-300"/>
          </div>

          <!-- Vertical Divider -->
          <!-- <div class="vertical-divider hidden lg:block"></div> -->
          <div class="vertical-divider hidden lg:flex justify-center items-center w-[50px]">
            <div class="w-[2px] h-full bg-gray-300"></div>
            <span class="mx-4 text-sm text-gray-500 self-center" style="background: #fff; padding: 20px;">OR</span>
            <div class="w-[2px] h-full bg-gray-300"></div>
          </div>

          <!-- Custom Email Login Section -->
          <section class="flex-grow w-full ps-[18px] m-[10px] mt-0 sm:mt-0 custom-login-container">
            @if (activeForm === 'login') {
              <div>
                <form (ngSubmit)="submitEmailLogin()">
                  <div class="email-login">
                    <input
                        type="email"
                        id="email"
                        [(ngModel)]="email"
                        name="email"
                        placeholder="Email"
                        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm input-style placeholder-[#25293166]"
                        required
                    />
                  </div>
                  <div class="password-login mt-4 relative">
                    <input
                        type="password"
                        id="password"
                        [(ngModel)]="password"
                        placeholder="Password"
                        name="password"
                        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm input-style placeholder-[#25293166] password-button"
                        required
                    />
                  </div>
                  <p class="font-prop-normal text-left text-[14px] leading-[25.5px] font-poppins mt-2 ps-[18px]">
                    Forgot your password?
                    <span
                        class="text-[#d13e69] font-poppins cursor-pointer"
                        (click)="forgotPassword()">Press here</span>.
                  </p>
                  @if (loginError) {
                    <div class="text-red-500 text-sm">
                      {{ loginError }}
                    </div>
                  }
                  <div class="parent-container">
                    <button type="submit"
                            class="mt-6 black-button text-white px-4 py-2 rounded-lg w-full xl:!w-[185px] lg:!w-[148px] sm:!w-[217px] !w-[101px] main-button-design mx-auto">
                      Log In
                    </button>
                  </div>
                </form>
              </div>
            }

            <!-- Register Form (show if isRegisterMode is true) -->
            @if (activeForm === 'register') {
              <div>
                <form (ngSubmit)="submitRegisterForm()">
                  <div class="email-login">
                    <input
                        type="email"
                        id="registerEmail"
                        [(ngModel)]="registerEmail"
                        name="registerEmail"
                        placeholder="Email"
                        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm input-style placeholder-[#25293166]"
                        required
                    />
                  </div>
                  <div class="password-login mt-4 relative">
                    <div
                      class="password-popup"
                      [ngClass]="{'show': showPasswordPopup}"
                    >
                      <ul>
                        <li [ngClass]="{'active': registerPassword.length >= 4}">
                          <span *ngIf="registerPassword.length >= 4" class="checkbox">&#10003;</span>
                          <span *ngIf="registerPassword.length < 4" class="checkbox">&#10007;</span>
                          At least 4 characters
                        </li>
                        <li [ngClass]="{'active': hasLowercase(registerPassword)}">
                          <span *ngIf="hasLowercase(registerPassword)" class="checkbox">&#10003;</span>
                          <span *ngIf="!hasLowercase(registerPassword)" class="checkbox">&#10007;</span>
                          At least 1 lowercase letter
                        </li>
                      </ul>
                    </div>
                    <input
                        type="password"
                        id="registerPassword"
                        [(ngModel)]="registerPassword"
                        (focus)="showPasswordPopup = true"
                        (blur)="showPasswordPopup = false"
                        name="registerPassword"
                        placeholder="Password"
                        class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm input-style placeholder-[#25293166]"
                        required
                    />
                  </div>
                  @if (registerError) {
                    <div class="text-red-500 text-sm">
                      {{ registerError }}
                    </div>
                  }
                  <div class="parent-container">
                    <button type="submit"
                            class="mt-6 black-button text-white px-4 py-2 rounded-lg w-full xl:!w-[185px] lg:!w-[148px] sm:!w-[217px] !w-[101px] main-button-design mx-auto">
                      Register
                    </button>
                  </div>
                </form>
                <!-- Link to switch back to the login form -->
                <p class="font-prop-normal text-center text-[14px] leading-[25.5px] font-poppins mt-4">
                  Already have an account?
                  <span class="text-[#d13e69] font-poppins cursor-pointer" (click)="showLoginForm()">Log in here</span>.
                </p>
              </div>
            }

            <!-- Forget Password Form -->
            @if (activeForm === 'forgotPassword') {
              <div>
                @if (activeSubForm === 'emailCode') {
                  <div>
                    <form (ngSubmit)="submitForgotPasswordForm()">
                      <div class="email-forgotpassword">
                        <input
                            type="email"
                            id="forgotPasswordEmail"
                            placeholder="Email"
                            [(ngModel)]="forgotPasswordEmail"
                            name="forgotPasswordEmail"
                            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm input-style placeholder-[#25293166]"
                            required
                        />
                      </div>
                      @if (registerError) {
                        <div class="text-red-500 text-sm">
                          {{ registerError }}
                        </div>
                      }
                      <div class="parent-container">
                        <button type="submit"
                                class="mt-6 black-button text-white px-4 py-2 rounded-lg w-full xl:!w-[185px] lg:!w-[148px] sm:!w-[217px] !w-[101px] main-button-design mx-auto">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                }
                <!-- @if (activeSubForm === 'verifyCode') {
                  <div>
                    <form (ngSubmit)="submitVerificationCode()" class="text-center">
                      <div class="flex justify-center space-x-2">
                        @for (digit of verificationCode; track digit; let i = $index) {
                          <input
                              id="code-input-{{i}}"
                              maxlength="1"
                              type="text"
                              class="text-center border border-gray-300 rounded-md shadow-sm p-2 w-10 h-12 text-xl"
                              [(ngModel)]="verificationCode[i]"
                              name="code-{{i}}"
                              (input)="onCodeInput(i, $event)"
                              (keydown)="onCodeInput(i, $event)"
                              (paste)="i === 0 ? onPaste($event) : null"
                              required/>
                        }
                      </div>
                      <div class="parent-container">
                        <button type="submit"
                                class="mt-6 black-button text-white px-4 py-2 rounded-lg w-full xl:!w-[185px] lg:!w-[148px] sm:!w-[217px] !w-[101px] main-button-design mx-auto">
                          Verify
                        </button>
                      </div>
                    </form>
                  </div>
                } -->
                @if (activeSubForm === 'resetPassword') {
                  <div>
                    <form (ngSubmit)="submitResetPasswordForm()" class="text-center">
                      <div class="password-login mt-4 relative">
                        <div
                          class="password-popup"
                          [ngClass]="{'show': showPasswordPopup}"
                        >
                          <ul>
                            <li [ngClass]="{'active': forgotPasswordNewPassword.length >= 4}">
                              <span *ngIf="forgotPasswordNewPassword.length >= 4" class="checkbox">&#10003;</span>
                              <span *ngIf="forgotPasswordNewPassword.length < 4" class="checkbox">&#10007;</span>
                              At least 4 characters
                            </li>
                            <li [ngClass]="{'active': hasLowercase(forgotPasswordNewPassword)}">
                              <span *ngIf="hasLowercase(forgotPasswordNewPassword)" class="checkbox">&#10003;</span>
                              <span *ngIf="!hasLowercase(forgotPasswordNewPassword)" class="checkbox">&#10007;</span>
                              At least 1 lowercase letter
                            </li>
                          </ul>
                        </div>
                        <input
                            type="password"
                            id="forgotPasswordNewPassword"
                            [(ngModel)]="forgotPasswordNewPassword"
                            (focus)="showPasswordPopup = true"
                            (blur)="showPasswordPopup = false"
                            name="forgotPasswordNewPassword"
                            placeholder="New Password"
                            class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm input-style placeholder-[#25293166]"
                            required
                        />
                      </div>
                      @if (resetPasswordError) {
                        <div class="text-red-500 text-sm">
                          {{ resetPasswordError }}
                        </div>
                      }
                      <div class="parent-container">
                        <button type="submit"
                                class="mt-6 black-button text-white px-4 py-2 rounded-lg w-full xl:!w-[185px] lg:!w-[148px] sm:!w-[217px] !w-[101px] main-button-design mx-auto">
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                }
                <!-- Link to switch back to the login form -->
                <p class="font-prop-normal text-center text-[14px] leading-[25.5px] font-poppins mt-4">
                  Already have an account?
                  <span class="text-[#d13e69] font-poppins cursor-pointer" (click)="showLoginForm()">Log in here</span>.
                </p>
              </div>
            }

            <!-- Divider -->
            <!-- <div class="divider flex items-center my-4">
            <hr class="flex-grow border-gray-300" />
            <span class="mx-2 text-sm text-gray-500">OR</span>
            <hr class="flex-grow border-gray-300" />
          </div> -->
          </section>
        </div>
      </div>
    }
  </div>
</div>

<div [@fadeTransition] class="w-full flex justify-center" style="display: none;">
  <div [ngClass]="{'lg:!w-[845px] sm:!w-[677px]': (user$ | async) && (userNeedToRegister$ | async)}"
       class="white-container sm:w-[596px] sm:max-w-none w-[90%] max-w-[596px] !rounded-[30.3px] md:!rounded-[42px]">
    @if ((user$ | async) && (userNeedToRegister$ | async)) {
      <!-- <sbz-register [activeStep]="activeStep" [submitForm]="submitForm"
                    (forceStepChange)="changeActiveStep($event)"></sbz-register> -->
    } @else {
      <div class="text-center relative">
        <p class="font-prop-normal lg:text-[38px] sm:text-[38px] text-[20px] font-light">Ready <strong
            class="font-bold">to Amplify
          Your</strong>
          Music?</p>
        <p class="font-prop-normal lg:text-[15px] lg-px-0 sm:text-[18px] text-[11px] px-2">Select an option below to
          <strong
              class="font-bold">log
            in</strong>
          or create a new account.</p>
      </div>
      <section [class.opacity-0]="hideDescope$ | async"
               class="transition-opacity duration-500 lg:ps-[68px] lg:pe-[49px] ps-[18px] m-[15px] descope-container">
        <descope (error)="onError($event)"
                 (ready)="onReady()"
                 (success)="onSuccess($event)"
                 [flowId]="flowId">
        </descope>
      </section>
    }
  </div>
</div>

@if ((user$ | async) && (userNeedToRegister$ | async) && !(userNeedToVerifyEmail$ | async)) {
  <div class="w-full flex justify-center lg:mt-[34px] sm:mt-[63px] mt-[28px]">
    <div class="lg:w-[845px] sm:w-[677px] sm:max-w-none max-w-[596px] w-[90%] flex justify-between sm:px-[60px]">
      <!--      <button [id]="(activeStep === 3) ? 'step-'+activeStep+'-back-finish' : 'step-'+activeStep+'-back'"-->
      <!--              class="main-button-design black-button lg:!w-[185px] sm:!w-[217px] !w-[101px]"-->
      <!--              [class.disabled]="activeStep === 1"-->
      <!--              (click)="changeActiveStep(activeStep - 1)">BACK-->
      <!--      </button>-->
      <!--      @if (activeStep === 3) {-->
      <!--        <button [id]="'step-'+activeStep+'-finish'"-->
      <!--                class="main-button-design gradient-button lg:!w-[185px] sm:!w-[217px] !w-[101px]" (click)="submit()">-->
      <!--          FINISH-->
      <!--        </button>-->
      <!--      } @else {-->
      <!--        <button [id]="'step-'+activeStep+'-next'"-->
      <!--                class="main-button-design black-button lg:!w-[185px] sm:!w-[217px] !w-[101px]"-->
      <!--                (click)="changeActiveStep(activeStep + 1)">NEXT-->
      <!--        </button>-->
      <!--      }-->
      <button class="main-button-design gradient-button lg:!w-[185px] sm:!w-[217px] !w-[101px] ml-auto"
              (click)="submit()">
        Register
      </button>
    </div>
  </div>
}

