<footer [@fadeTransition]>
  <div class="session-small-logo">
    <img [routerLink]="'/'" alt="Image distribute 1" class="cursor-pointer" fill
         ngSrc="../../../assets/images/footer/small_logo.webp" priority>
  </div>
  <div class="socials-links">
    <a [routerLink]="'/'">Home</a>
    <a [routerLink]="'/about'">About Us</a>
    <a [routerLink]="'/contact'">Contact Us</a>
    <a [routerLink]="'/help'">Help and Support</a>
  </div>
</footer>
