import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ModalService} from '../../services/modal.service';

@Component({
  selector: 'sbz-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrl: './file-upload.component.scss'
})
export class FileUploadComponent {
  @Input() fileType: 'audio/*' | 'image/*' | 'video/*' = 'audio/*'; // Default to audio files
  @Input() multiple = false; // Single file upload by default
  @Input() userImage: string | undefined = undefined; // Single file upload by default
  @Output() base64ImageCreated = new EventEmitter<string>();
  @ViewChild('fileInput') fileInput!: ElementRef;
  files: File[] = [];
  uploadProgress: number = 0;
  isDraggingOver: boolean = false;

  constructor(private modalService: ModalService) {
  }

  onDrop(event: DragEvent) {
    event.preventDefault();
    if (event.dataTransfer && event.dataTransfer.files.length > 0) {
      this.handleFileInput(event.dataTransfer.files);
    }
    this.isDraggingOver = false;
  }

  onDragOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.isDraggingOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.isDraggingOver = false;
  }

  handleFileInput(input: FileList | HTMLInputElement) {
    this.userImage = undefined;
    let files: FileList;

    // Determine the type of the input argument and extract the FileList accordingly
    if (input instanceof HTMLInputElement && input.files) {
      files = input.files;
    } else if (input instanceof FileList) {
      files = input;
    } else {
      // If the input is neither HTMLInputElement with files nor FileList, log an error or handle as needed
      this.modalService.openErrorModal('There was an error loading the image. Please try again.');
      return;
    }

// Check if any of the files exceed the size limit of 9.99 MB
    const maxSize = 9.99 * 1024 * 1024; // 9.99 MB in bytes
    const oversizeFiles = Array.from(files).filter(file => file.size > maxSize);

    if (oversizeFiles.length > 0) {
      // If there are oversize files, log a warning and remove them
      this.modalService.openErrorModal('One or more files exceed the size limit of 10 MB. Please select smaller files.');
      return;
    }

    // Process the files (either from the input or drag-and-drop)
    this.files = this.multiple ? Array.from(files) : [files[0]];
    this.uploadFiles(); // Here, add your logic to handle the upload
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  uploadFiles() {
    const file = this.files[0];
    const reader = new FileReader();

    reader.onload = (event: ProgressEvent<FileReader>) => {
      // This is where you get the base64 string
      this.base64ImageCreated.next(reader.result as string);
    };

    reader.onerror = (error) => {
      this.modalService.openErrorModal('There was an error loading the image. Please try again.');
      console.error('Error reading file:', error);
    };

    // Start reading the file as Data URL (base64 string)
    reader.readAsDataURL(file);
  }

  getImageUrl() {
    if (this.userImage) {
      return this.userImage;
    }

    if (this.files.length > 0 && this.fileType === 'image/*') {
      return URL.createObjectURL(this.files[0]);
    }

    return null;
  }
}
