<div [@fadeTransition] class="article-container">
  <div class="flex flex-col lg:flex-row lg:pt-[51px] pt-[80px]">
    <div class="article-content">
      <p class="article-content__title xl:-mt-[11px] sm:-mt-[30px] -mt-[50px]">
        Frequently Asked Questions
      </p>
      <p class="article-content__subtitle">
        How much does the service cost?
      </p>
      <p class="article-content__text">
        The service is provided for the purchase of an annual subscription.
        <br>You can choose the plan that suits you.
      </p>

      <p class="article-content__subtitle">
        Do you charge a fee beyond the subscription payment?
      </p>
      <p class="article-content__text">We do not charge a fee.
        <br> All the income received goes directly to you in full.</p>

      <p class="article-content__subtitle">
        How do you upload a song?
      </p>
      <p class="article-content__text">On our support page on the website, you will find an instructional video on the
        topic.</p>

      <p class="article-content__subtitle">
        To which stores do you distribute?
      </p>
      <p class="article-content__text">
        As of today, we distribute to 29 digital stores. All the "important" and active stores are on the
        list. The most notable ones are: Spotify, Apple Music, TikTok, YouTube Music, Instagram. It is
        important to emphasize that the list is updated according to changes in the music market.
      </p>

      <p class="article-content__subtitle">
        If I already have an artist page, how do I ensure the new song appears there?
      </p>
      <p class="article-content__text">During the song creation process, you will be asked to mention the main artist's
        name. You can
        add additional roles like guest artist or producer. If there are existing artist pages on Spotify and
        Apple Music, you should add links to them. You will find an explanation in the video.</p>

      <p class="article-content__subtitle">
        Do you upload sounds to TikTok and Reels?
      </p>
      <p class="article-content__text">Absolutely yes.
        <br>In the instructional video for uploading a song, you can find the stage where you
        choose the timing (start time of the sound.
      </p>

      <p class="article-content__subtitle">
        Can you choose the release date?
      </p>
      <p class="article-content__text">You can release immediately or schedule the song for a date of your choice. We
        always
        recommend scheduling at least 10 days ahead.
      </p>

      <p class="article-content__subtitle">
        How do you receive payment?
      </p>
      <p class="article-content__text">You receive a report at the end of each month where you can see how much money
        your music
        has made and on which platforms. Payment is made up to a month from the report date. Payment
        is transferred to your bank account via the Tipalti service. The minimum transfer amount is $50.</p>

      <p class="article-content__subtitle">What other data can be received?</p>
      <p class="article-content__text">Listening data from various platforms, updated and available for viewing, as well
        as monthly
        reports with advanced data segmentation.
      </p>

      <p class="article-content__subtitle">How do you collect YouTube royalties?</p>
      <p class="article-content__text">We collect YouTube royalties for you. It is important to emphasize that we do not
        do this
        through your official YouTube page, but through the YouTube Content ID system, which allows
        us to identify uses of your song and collect royalties on them.
      </p>

      <p class="article-content__subtitle">How do you sync the song lyrics to various platforms?</p>
      <p class="article-content__text">
        Lyrics synchronization is done through an external software that allows you to sync lyrics to
        your music.<br>
        You are welcome to open an account and start syncing
        <a class="underline font-bold" href="https://pro.musixmatch.com/" target="_blank">
          Musixmatch
        </a>
      </p>

      <p class="article-content__subtitle">How long does it take for my song to appear on a digital platform?</p>
      <p class="article-content__text">Each platform takes a different amount of time for the song to appear. Some take
        two days and
        some take six days. If you want everything to go live together, we recommend scheduling the
        song at least 10 days ahead.
      </p>

      <p class="article-content__subtitle">Can you upload covers and remixes?</p>
      <p class="article-content__text">Covers can be uploaded only if there have been no changes to the lyrics and
        melody and credits
        are given to the writers. A remix can be uploaded only if there is approval from the master
        owners. Before the single is approved in the system, you will be asked to present this approval.
      </p>

      <p class="article-content__subtitle">When and how can you contact support and customer service?</p>
      <p class="article-content__text">
        Enter our support page here
        <a class="underline font-bold" routerLink="/contact">here</a><br>
        We are available Sunday to Thursday between 10:00-17:00 israel
        time.<br> We will try to respond within two hours.
      </p>

    </div>
    <div class="articles">
      <div [routerLink]="'/contact'" class="action cursor-pointer">
        <div class="action--img">
          <img alt="subscription" fill ngSrc="assets/images/help/contact.webp">
        </div>
        <p class="action--title">Contact Support:</p>
        <p class="action--subtitle">Got a question or facing an
          issue? Our support team is
          here to help. Send us a
          message!</p>
      </div>
    </div>
  </div>
  <sbz-footer></sbz-footer>
</div>
