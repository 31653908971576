import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {UntilDestroy} from '@ngneat/until-destroy';
import {UserStateService} from '../../services/user-state.service';
import {BehaviorSubject, finalize} from 'rxjs';
import {fadeTransition} from '../../animations/fade-in-animation';
import {ModalService} from '../../services/modal.service';
import {MobileDetectionService} from '../../services/mobile-detection.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {SubscriptionService} from '../../services/subscription.service';
import {StripeService} from '../../services/stripe.service';
import {LoadingService} from '../../services/loading.service';

@UntilDestroy({checkProperties: true})
@Component({
  selector: 'sbz-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  animations: [fadeTransition],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProfileComponent implements OnInit {
  flowId = environment.descopeFlowId;
  activeStep: number = 1;
  submitForm = false;
  hideDescope$ = new BehaviorSubject<boolean>(true);
  user$ = this.userStateService.user$;
  userNeedToRegister$ = this.userStateService.userNeedToRegister$;
  userName: string = '';

  planName = 'Basic Plan';
  planPrice = '$9.99/month';

  personalInfoForm: FormGroup;
  creditCardForm: FormGroup;

  @ViewChild('cardNumber') cardNumberRef!: ElementRef;
  @ViewChild('cardExpiry') cardExpiryRef!: ElementRef;
  @ViewChild('cardCvc') cardCvcRef!: ElementRef;

  cardNumber: any;
  cardExpiry: any;
  cardCvc: any;
  cardNumberError!: string;
  cardExpiryError!: string;
  cardCvcError!: string;

  editingPersonalInfo = false;
  editingCreditCard = false;
  validRoles = ['Artist', 'Artist Manager', 'Distributor'];

  private descopeSocialLoginContainer = '#PN5ZN_viow';

  constructor(private userStateService: UserStateService,
              private subscriptionService: SubscriptionService,
              private mobileDetectionService: MobileDetectionService,
              private modalService: ModalService,
              private cdr: ChangeDetectorRef,
              private httpClient: HttpClient,
              private fb: FormBuilder,
              private stripeService: StripeService,
              private loadingService: LoadingService) {
    this.personalInfoForm = this.fb.group({
      userName: [{ value: '', disabled: true }],
      userPhone: [],
      userEmail: [{ value: '', disabled: true }],
      userRole: [''],
    });

    this.creditCardForm = this.fb.group({
      cardNumber: ['**** **** **** ***'],
      expirationDate: ['**/**'],
      cvc: ['***']
    });

    subscriptionService.subscriptionPackages$.subscribe(packages => {
      console.log('packages: ', packages);
    });
  }

  ngOnInit() {
    this.user$.subscribe(user => {
      if (user && user.displayName) {

        this.subscriptionService.subscriptionPackages$.subscribe(packages => {
          this.userName = user.displayName.split(' ')[0];
          this.personalInfoForm.patchValue({
            userName: user.displayName,
            userPhone: user.phone,
            userEmail: user.email,
            userRole: user.role,
            planName: user.selectedPackageId,
          });
        });
      }
      this.cdr.markForCheck();
    });
  }

  ngAfterViewInit(): void {
    this.initializeStripe();
  }

  private initializeStripe(): void {
    if (!this.cardNumberRef || !this.cardExpiryRef || !this.cardCvcRef) {
      return;
    }
    const lineHeight = this.mobileDetectionService.isMobile$.getValue() ? '40px' : '45px';
    const stripeStyle = {
      base: {
        color: '#000',
        fontSize: '16px',
        fontWeight: '300',
        letterSpacing: '-0.16px',
        fontFamily: '"Poppins", ui-sans-serif, sans-serif',
        '::placeholder': {
          color: '#25293166'
        },
        border: '1px solid rgba(37, 41, 49, 0.3)',
        borderRadius: '29.7px',
        padding: '12px 36px',
        lineHeight,
        backgroundColor: '#fff',
        paddingLeft: '36px',
      },
      invalid: {
        color: '#f00',
        iconColor: '#f00'
      }
    };

    this.cardNumber = this.stripeService.createCardNumberElement(stripeStyle);
    this.cardNumber.mount(this.cardNumberRef.nativeElement);
    this.cardNumber.on('change', (event: any) => {
      this.cardNumberError = event.error ? event.error.message : undefined;
    });

    this.cardExpiry = this.stripeService.createCardExpiryElement(stripeStyle);
    this.cardExpiry.mount(this.cardExpiryRef.nativeElement);
    this.cardExpiry.on('change', (event: any) => {
      this.cardExpiryError = event.error ? event.error.message : undefined;
    });

    this.cardCvc = this.stripeService.createCardCvcElement(stripeStyle);
    this.cardCvc.mount(this.cardCvcRef.nativeElement);
    this.cardCvc.on('change', (event: any) => {
      this.cardCvcError = event.error ? event.error.message : undefined;
    });
  }

  onSuccess(event: CustomEvent) {
    setTimeout(() => {
      this.userStateService.updateUserFromDescope(event.detail.sessionJwt, event.detail.user.picture);
      this.mobileDetectionService.isMobile$
        .subscribe({
          next: isMobile => this.onReady()
        });
    });
  }

  onError(event: CustomEvent) {
    this.modalService.openErrorModal('An error occurred while retrieving user state. Please refresh the page.');
    console.error('ERROR FROM LOG IN FLOW', event.detail);
  }

  onReady() {
    const descope = document.querySelector('descope-wc')!;
    const wcRoot = descope?.shadowRoot?.getElementById('wc-root')!;
    const mainContainer: HTMLElement = wcRoot?.querySelector('descope-container')!;
    if (mainContainer) {
      mainContainer.style.width = '100%';
      mainContainer.style.marginLeft = '-1rem';
      mainContainer.style.marginRight = '-1rem';
      mainContainer.style.marginTop = '-1rem';
    }
    const descopeLoginButtonsContainer = mainContainer?.querySelector(this.descopeSocialLoginContainer);

    if (descopeLoginButtonsContainer) {
      this.setLoginButtonsStyle(descopeLoginButtonsContainer);
      this.hideDescope$.next(false);
    }
  }

  changeActiveStep(activeStep: number) {
    this.activeStep = activeStep;
    this.cdr.detectChanges();
  }

  submit() {
    this.submitForm = true;
  }

  editPersonalInfo() {
    this.editingPersonalInfo = !this.editingPersonalInfo;
  }

  editCreditCard() {
    this.editingCreditCard = !this.editingCreditCard;
    this.initializeStripe();
  }

  savePersonalInfo() {
    // Save personal info logic
    console.log(this.personalInfoForm.value);
    this.editingPersonalInfo = false;
    this.userStateService.updateUserInformation(this.personalInfoForm.value);
  }

  async saveCreditCard(): Promise<void> {
    this.loadingService.toggleLoading(true);
    const {paymentMethod, error} = await this.stripeService.createNewPaymentMethod(this.cardNumber);

    console.log({
      type: 'card',
      card: this.cardNumber,
    });

    if (error) {
      console.error(error);
      return;
    }

    try {
      const url = `${environment.baseUrl}/subscriptions/update-payment-method`;

      this.httpClient.post(url, {
        paymentMethodId: paymentMethod.id
      }).pipe(
        finalize(() => {
          this.loadingService.toggleLoading(false);
          this.editingCreditCard = false;

          this.cdr.markForCheck();
        })
      ).subscribe({
        error: (err) => {
          console.error(err);
          alert('An error occurred while updating the payment method');
        }
      }
      );
    } catch (err) {
      console.error(err);
      alert('An error occurred while updating the payment method');
    }
  }

  private setLoginButtonsStyle(descopeLoginButtonsContainer: Element) {
    let fontSize = this.mobileDetectionService.isMobile$.getValue() ? 16 : 24.5;

    const loginButtonsCss = `
        width: 100%;
        border-radius: 42px;
        border-color: rgba(37, 41, 49, 0.3);
        font-size: ${fontSize}px;
        font-weight: 500;
        color: #252931;
    `;
    const googleButton: HTMLElement = descopeLoginButtonsContainer.querySelector('descope-button[data-descope-provider="google"]')!;
    googleButton.style.cssText = `${loginButtonsCss} color: #252931;`;

    const facebookButton: HTMLElement = descopeLoginButtonsContainer.querySelector('descope-button[data-descope-provider="facebook"]')!;
    facebookButton.style.cssText = `${loginButtonsCss} color: white; background-color: #4285f4;`;
  }
}
