<div [@fadeTransition] class="contact-container"
     [ngClass]="{'message-sent': messageSent$ | async}">
  <section class="flex-grow flex">
    <div class="flex flex-col w-full">
      @if (messageSent$ | async) {
        <section [@fadeTransition] class="sent-container">
          <h2 class="main-title">Message
            <strong class="font-bold">Sent!</strong></h2>
          <p class="hidden lg:block sub-text">Thank you for contacting us! We’ll get back to you shortly.</p>
          <p class="block lg:hidden sub-text">Thank you for contacting us!<br>We’ll get back to you shortly.</p>
        </section>
      } @else {
        <section class="send-container">
          <h2 class="main-title">Get in Touch
            <strong class="font-bold">With Us!</strong></h2>
          <p class="hidden lg:block sub-text">Just Send us a message and we’ll get back to you!</p>
          <p class="block lg:hidden sub-text">Just Send us a message and<br>we’ll get back to you!</p>

          <form (ngSubmit)="onSubmit()" [formGroup]="form">
            <div class="form-container">
              <div class="form-container__name-email">
                <div class="contact-input lg:mb-0 sm:mb-[40px] mb-[18px]">
                  <input type="text" placeholder="Name" formControlName="name"
                         class="input-style placeholder-[#25293166]">
                  @if (getErrorForField('name')) {
                    <div class="invalid-input-container">
                      <img src="assets/images/inputs/input-invalid.webp" alt="invalid" class="w-[14px] h-[13px]">
                      <span class="invalid-input">Name is required</span>
                    </div>
                  }
                </div>

                <div class="contact-input">
                  <input type="email" placeholder="Email" formControlName="email"
                         class="input-style placeholder-[#25293166]">
                  @if (getErrorForField('email')) {
                    <div class="invalid-input-container">
                      <img src="assets/images/inputs/input-invalid.webp" alt="invalid" class="w-[14px] h-[13px]">
                      <span class="invalid-input">A valid Email is required</span>
                    </div>
                  }
                </div>
              </div>

              <div class="form-container__msg">
                <input placeholder="Message" formControlName="message"
                       class="input-style placeholder-[#25293166]">
                @if (getErrorForField('message')) {
                  <div class="invalid-input-container">
                    <img src="assets/images/inputs/input-invalid.webp" alt="invalid" class="w-[14px] h-[13px]">
                    <span class="invalid-input">You must enter a message</span>
                  </div>
                }
              </div>

              <div class="form-container__button">
                <button class="main-button-design gradient-button lg:!w-[25%] !w-full" type="submit">
                  SEND
                </button>
              </div>
            </div>
          </form>
        </section>
      }
    </div>

    <div class="image-container">
      <img alt="Image distribute 2" fill ngSrc="assets/images/contact-us/contact.webp" priority
           srcset="assets/images/contact-us/contact@2x.webp 2x,
                   assets/images/contact-us/contact@3x.webp 3x">
    </div>
  </section>

  <sbz-footer class="lg:hidden !mt-[74px]"></sbz-footer>
</div>
<sbz-footer class="lg:block hidden !mt-[40px]"></sbz-footer>
