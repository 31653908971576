<div [@fadeTransition] class="flex flex-col items-center">
  <div class="main-text">
    <p class="main-text__header">Empowering <strong class="font-bold">Independent
      <br>Music</strong> Distribution
    </p>
    <p class="main-text__subtext-mobile">Unleash your music's
      potential
      with Dstro,<br> the
      next-generation music distribution platform</p>
    <p class="main-text__subtext-desktop">
      Unleash your music's potential with Dstro, the next-generation<br>
      music distribution platform</p>
    <button (click)="checkLogin()" class="main-button-design gradient-button lg:mt-[37px] mt-[42px]">
      Start Using Dstro
    </button>
  </div>

  <div class="first-image-container">
    <img alt="Image distribute 1" fill ngSrc="../../../../assets/images/distribute/img-1.webp" priority>
  </div>

  <div class="section-container justify-between xl:mt-[110px] mt-[65px]">
    <div class="section-container--text text-one">
      <div class="distribute-item-title">
        <h2 class="lg:-mb-[12px]">Redefining</h2>
        <strong class="font-bold">Music distribution</strong>
      </div>
      <p class="distribute-item-text">Dstro offers advanced music distribution tools
        designed for the digital age. We cater to music
        professionals worldwide, helping shape the future of
        the industry</p>
      <button (click)="checkLogin()" class="main-button-design gradient-button">
        Start Using Dstro
      </button>
    </div>

    <div class="second-image-container">
      <img alt="Image distribute 2" fill ngSrc="../../../../assets/images/distribute/img-2.webp">
    </div>
  </div>

  <div class="section-container lg:mt-0 mt-[50px]">
    <div class="hidden lg:block third-image-container">
      <img alt="Image distribute 3" fill ngSrc="../../../../assets/images/distribute/img-3.webp">
    </div>

    <div class="section-container--text text-two">
      <div class="distribute-item-title">
        <h2 class="lg:-mb-[12px]">In-depth</h2>
        <strong class="font-bold">Revenue Reporting</strong>
      </div>
      <p class="distribute-item-text">Gain access to detailed, real-time revenue
        analytics.
        Make data-driven decisions and maximize your
        earnings with our transparent reporting tools.</p>
      <button (click)="checkLogin()" class="main-button-design gradient-button">
        Start Using Dstro
      </button>
    </div>

    <div class="lg:hidden third-image-container">
      <img alt="Image distribute 3" fill ngSrc="../../../../assets/images/distribute/img-3.webp">
    </div>
  </div>

  <div class="section-container xl:mt-[90px] justify-between">
    <div class="section-container--text text-three">
      <div class="distribute-item-title">
        <h2 class="lg:-mb-[12px]">Global</h2>
        <strong class="font-bold">Distribution & Delivery</strong>
      </div>
      <p class="distribute-item-text">
        Expand your music's reach and monetize it across all
        major platforms and digital service providers. With
        Dstro, your music travels further.
      </p>
      <button (click)="checkLogin()" class="main-button-design gradient-button">
        Start Using Dstro
      </button>
    </div>

    <div class="fourth-image-container">
      <img alt="Image distribute 4" fill ngSrc="../../../../assets/images/distribute/img-4.webp">
    </div>
  </div>

  <div class="section-container justify-between gap-4">
    <div class="flex flex-col items-center justify-center w-full text-center mx-auto">
      <h2 class="distribute-item-title xl:!mb-4 lg:!mb-2 !whitespace-break-spaces">Experience
        <strong class="font-bold">the Future of Music</strong> Distribution
      </h2>
      <p class="distribute-item-text !text-center xl:w-[800px] lg:w-[600px] w-[80%]">Try Dstro now and take the first
        step
        towards transforming your
        music distribution journey.</p>
      <button (click)="checkLogin()" class="main-button-design gradient-button">
        Start Using Dstro
      </button>
    </div>
  </div>

  <sbz-footer></sbz-footer>
</div>

