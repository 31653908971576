<div [@fadeTransition] class="flex flex-col items-center px-4 xl:mt-[93px] lg:mt-[96px] mt-[100px]">
  <div class="relative flex items-center mt-4">
    <img *ngIf="user$ | async as user" [src]="user.pictureUrl" alt="{{ userName }}" class="rounded-full w-24 h-24"/>
    <button
        class="edit-button absolute bottom-[15px] right-[15px] bg-white-500 text-white p-2 rounded-full transform translate-x-1/2 translate-y-1/2">
      <img alt="Edit" class="h-6 w-6" src="assets/images/profile/edit.webp"/>
    </button>
  </div>
  <div class="main-text mt-10">
    <p class="main-text__header">Welcome back, <strong class="font-bold">{{ userName }}!</strong></p>
  </div>
</div>

<div [@fadeTransition] class="container mx-auto px-4 py-6">
  <div class="grid grid-cols-1 lg:grid-cols-3 gap-6">

    <!-- Personal Info Column -->
    <div class="p-4 rounded-lg">
      <div class="flex justify-between items-center border-b pb-1 mb-4">
        <h2 class="text-xl font-semibold">Personal Info</h2>
        <button (click)="editPersonalInfo()" class="edit-button text-blue-500 relative">
          <img alt="Edit" class="edit-button h-6 w-6" src="assets/images/profile/edit.webp"/>
        </button>
      </div>
      <form [formGroup]="personalInfoForm" class="space-y-4">
        <div>
          <label class="block text-gray-700">Name</label>
          <p *ngIf="!editingPersonalInfo" class="font-bold">{{ personalInfoForm.get('userName')?.value }}</p>
          <input *ngIf="editingPersonalInfo" class="border rounded p-2 w-full" formControlName="userName" [disabled]=true />
        </div>
        <div>
          <label class="block text-gray-700">Phone</label>
          <p *ngIf="!editingPersonalInfo" class="font-bold">{{ personalInfoForm.get('userPhone')?.value }}</p>
          <input *ngIf="editingPersonalInfo" class="border rounded p-2 w-full" formControlName="userPhone"/>
        </div>
        <div>
          <label class="block text-gray-700">Email</label>
          <p *ngIf="!editingPersonalInfo" class="font-bold">{{ personalInfoForm.get('userEmail')?.value }}</p>
          <input *ngIf="editingPersonalInfo" class="border rounded p-2 w-full" formControlName="userEmail" [disabled]=true />
        </div>
        <div>
          <label class="block text-gray-700">Role</label>
          <p *ngIf="!editingPersonalInfo" class="font-bold">{{ personalInfoForm.get('userRole')?.value }}</p>
          <select *ngIf="editingPersonalInfo" class="border rounded p-2 w-full" formControlName="userRole">
            <option *ngFor="let role of validRoles" [value]="role">{{ role }}</option>
          </select>
        </div>
        <button (click)="savePersonalInfo()" *ngIf="editingPersonalInfo"
                class="bg-blue-500 text-white px-4 py-2 rounded mt-4 main-button-design bg-[#252931]"
                style="width: 100%;"
                type="button">Save changes
        </button>
      </form>
    </div>
    <div class="p-4 rounded-lg">
      <div class="flex justify-between items-center border-b pb-1 mb-4">
        <h2 class="text-xl font-semibold">Credit Card Details</h2>
        <button (click)="editCreditCard()" class="edit-button text-blue-500">
          <img alt="Edit" class="h-6 w-6" src="assets/images/profile/edit.webp"/>
        </button>
      </div>
      <form [formGroup]="creditCardForm" class="space-y-4">
        <div>
          <label class="block text-gray-700">Card Number</label>
          <p *ngIf="!editingCreditCard" class="font-bold">{{ creditCardForm.get('cardNumber')?.value }}</p>
          <div #cardNumber class="border rounded w-full" [hidden]="!editingCreditCard"></div>
        </div>
        <div>
          <label class="block text-gray-700">Expiration Date</label>
          <p *ngIf="!editingCreditCard" class="font-bold">{{ creditCardForm.get('expirationDate')?.value }}</p>
          <div #cardExpiry class="border rounded w-full" [hidden]="!editingCreditCard"></div>
        </div>
        <div>
          <label class="block text-gray-700">CVC</label>
          <p *ngIf="!editingCreditCard" class="font-bold">{{ creditCardForm.get('cvc')?.value }}</p>
          <div #cardCvc class="border rounded w-full" [hidden]="!editingCreditCard"></div>
        </div>
        <button (click)="saveCreditCard()" *ngIf="editingCreditCard"
                class="main-button-design xl:mt-[37px] lg:mt-[30px] mt-[23px] bg-[#252931]"
                style="width: 100%;"
                type="button">Save changes
        </button>
      </form>
    </div>

    <!-- Subscription Plan Column -->
    <div class="p-4 rounded-lg">
      <div class="flex justify-between items-center border-b pb-1 mb-4">
        <h2 class="text-xl font-semibold">Subscription Plan</h2>
      </div>
      <div class="space-y-4">
        <p>You are currently using: <span class="font-bold">{{ planName }}</span></p>
        <div class="mt-4">
          <h3 class="text-lg font-semibold">Description</h3>
          <ul class="list-disc list-inside">
            <li>AI-Assisted Composition</li>
            <li>Rhythm Creation</li>
            <li>Pre-made Templates</li>
            <li>Instrument Library</li>
          </ul>
        </div>
        <!-- button will be disabled. rafi will do changes manually-->
        <!-- <button class="main-button-design gradient-button xl:mt-[37px] lg:mt-[30px] mt-[23px]">
          Upgrade to Business!
        </button> -->
      </div>
    </div>

  </div>
</div>
