import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './components/login/login.component';
import {ProfileComponent} from './components/profile/profile.component';
import {DistributeComponent} from './components/info-pages/distribute/distribute.component';
import {SubscribeComponent} from './components/subscribe/subscribe.component';
import {AboutUsComponent} from './components/info-pages/about-us/about-us.component';
import {ContactUsComponent} from './components/info-pages/contact-us/contact-us.component';
import {HelpAndSupportComponent} from './components/info-pages/help-and-support/help-and-support.component';
import {ReadMoreComponent} from './components/info-pages/articles/read-more/read-more.component';
import {WelcomeComponent} from './components/welcome/welcome.component';

const routes: Routes = [
  {path: '', component: DistributeComponent},  // Default route
  {path: 'login', component: LoginComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'subscribe', component: SubscribeComponent},
  // {path: 'distribute', component: DistributeComponent},
  {path: 'about', component: AboutUsComponent},
  {path: 'contact', component: ContactUsComponent},
  {path: 'help', component: HelpAndSupportComponent},
  {path: 'welcome', component: WelcomeComponent},

  {path: 'articles/read-more', component: ReadMoreComponent},

  // Redirection rule for trailing slashes
  {
    path: ':path/',
    pathMatch: 'full',
    redirectTo: '/:path'
  },

  // (Optional) Catch-all wildcard route for 404 handling or other purposes.
  // {path: '**', component: NotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
